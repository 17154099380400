import clsx from 'clsx'

export default function HeadingSection({ title, anchor, className }) {

    return (
        <div className="pt-16 flex items-center gap-x-2" id={anchor}>
            <span className={clsx(className, 'py-1 px-2 rounded-md')}></span>
            <span className="text-[#8a8f98] text-sm sm:text-base">
                {title}
            </span>
        </div>
    )
}
