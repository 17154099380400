import Navigation from "./components/navigation";
import Footer from "./components/footer";
import HeadingSection from "./components/heading-section";
import { Badge } from "./components/badge";
import HeadingTitle from "./components/heading-title";

function App() {
  return (
    <>
    <div className="mx-auto max-w-7xl px-4 ">
      <Navigation />

      <header>
        <h1 className="text-[#f7f8f8] pt-16 sm:pt-32 text-2xl sm:text-5xl leading-tight tracking-tight font-semibold animate-unblur-2 max-w-3xl">
          Building the future of cloud-native and AI development
        </h1>

        <h2 className="text-[#8a8f98] pt-8 pb-16 sm:pb-32 text-lg sm:text-2xl tracking-tighter font-medium animate-unblur-2.5 max-w-2xl">
          <span className="text-[#f7f8f8]">Beyond tomorrow.</span> Shaping the future where cloud-native architecture meets artificial intelligence.
        </h2>
      </header>



      <HeadingSection
        title="Our vision"
        anchor="our-vision"
        className="bg-green-500"
      />

      <HeadingTitle>Bridging cloud-native and AI innovation</HeadingTitle>
      <p className="text-[#8a8f98] pb-6 text-lg sm:text-xl tracking-tighter font-medium">
        The cloud-native landscape has evolved into a powerful but complex ecosystem. While teams have more tools than ever, they face increasing challenges in development, operations, and maintaining efficiency at scale.
      </p>
      <p className="text-[#8a8f98] pb-16 text-lg sm:text-xl tracking-tighter font-medium">
        <span className="text-[#f7f8f8]">We're solving this.</span> Through innovative products and expert guidance, we're creating solutions that combine cloud-native excellence with AI-powered intelligence - transforming how teams build and operate.
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-0 mb-16">
        <div className="bg-[#101010] p-12 sm:border sm:border-[#2a2a2a] sm:border-l-0">
          <h4 className="text-[#f7f8f8] text-lg sm:text-2xl font-semibold pb-4">
            Product Innovation
          </h4>
          <p className="text-[#8a8f98] text-sm sm:text-base pb-8">
            Building next-generation tools that simplify cloud-native operations and enhance developer productivity.
          </p>
          <div className="flex justify-left space-x-6">
            <Badge color="service">Black Citadel</Badge>
            <Badge color="pod">100x.sh</Badge>
          </div>
        </div>
        <div className="bg-[#101010] p-12 sm:border sm:border-[#2a2a2a] sm:border-r-0 sm:border-l-0">
          <h4 className="text-[#f7f8f8] text-lg sm:text-2xl font-semibold pb-4">
            Expert Consulting
          </h4>
          <p className="text-[#8a8f98] text-sm sm:text-base pb-8">
            Guiding teams through cloud-native architecture, Kubernetes operations, and AI integration.
          </p>
          <div className="flex justify-left space-x-1 md:space-x-6">
            <Badge color="service">Kubernetes</Badge>
            <Badge color="pod">Cloud Native</Badge>
            <Badge color="deployment">AI/ML</Badge>
          </div>
        </div>
      </div>
      <HeadingSection
        title="Our approach"
        anchor="our-approach"
        className="bg-blue-500"
      />
      <HeadingTitle>Building the future of cloud development</HeadingTitle>
      <p className="text-[#8a8f98] pb-6 text-lg sm:text-xl tracking-tighter font-medium">
        We believe in creating solutions that address real-world challenges. Our approach combines deep technical expertise with innovative thinking to deliver tools and guidance that make a meaningful impact.
      </p>
      <p className="text-[#8a8f98] text-lg sm:text-xl tracking-tighter font-medium">
        Whether through our products or consulting services, we're committed to helping teams navigate the evolving landscape of cloud-native development and artificial intelligence.
      </p>
      <HeadingSection
        title="Work with us"
        anchor="work-with-us"
        className="bg-fuchsia-500"
      />
      <HeadingTitle>Let's build something great</HeadingTitle>
      <p className="text-[#8a8f98] pb-6 text-lg sm:text-xl tracking-tighter font-medium">
        We're always excited to connect with teams who share our vision for the future of cloud-native development and AI innovation.
      </p>
      <p className="text-[#8a8f98] text-lg sm:text-xl tracking-tighter font-medium">
        Whether you're interested in our products or seeking expert guidance, we're here to help you navigate the complexities of modern development and operations.
      </p>

     
    </div>
    <Footer />
    </>
  );
}

export default App;
