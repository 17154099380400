import Navigation from "./components/navigation";
import Footer from "./components/footer";

function License() {
    return (
        <>
        <div className="mx-auto max-w-7xl px-4 ">
            <Navigation />

            <div class="text-[#8a8f98] min-h-screen">
                <div class="max-w-4xl mx-auto px-6 py-16">
                    <h1 class="text-[#f7f8f8] text-4xl font-semibold pb-8">License</h1>

                    <h2 class="text-[#f7f8f8] text-2xl font-semibold pb-4">Perpetual Fallback Licenses</h2>

                    <p class="pb-6 text-lg tracking-tighter">
                        The perpetual fallback license is our standard license that comes with every product,
                        except if we explicitly state otherwise.
                    </p>

                    <p class="pb-6 text-lg tracking-tighter">
                        Perpetual fallback licenses give you access to the latest version of the product that is available
                        on the day of your purchase forever. You can use this version as long as you wish but have to
                        extend the access to updates and support after every year.
                    </p>

                    <p class="pb-6 text-lg tracking-tighter">
                        You can usually renew the license yearly and lock in the latest version or upgrade to a
                        lifetime license at any time. If your license is still active and receives updates, upgrades
                        to lifetime are often discounted.
                    </p>

                    <h2 class="text-[#f7f8f8] text-2xl font-semibold pb-4">Lifetime Licenses</h2>


                    <p class="pb-6 text-lg tracking-tighter">
                        A lifetime license is similar to the perpetual fallback license but gives you access to all versions of
                        the software that we'll ever make in the future. So if you buy a lifetime license, you are set forever
                        and won't have to pay for updates of this product ever again.
                    </p>

                    <p class="pb-6 text-lg tracking-tighter">
                        Lifetime licenses often make sense for businesses that have a growing team of developers and want to
                        make sure that they can use the product in the future without having to pay for it again. You can
                        re-assign the license to a different user at any time, so you don't need to buy new licenses if
                        someone leaves your team and someone else joins.
                    </p>

                    <p class="pb-6 text-lg tracking-tighter">
                        We believe that lifetime licenses are healthy to our business as they allow us to plan ahead and
                        that there is an infinite amount of customers for our products. Every day, new developers join the
                        community and need tools to build their applications. We support them and won't run out of
                        customers anytime soon.
                    </p>

                    <h2 class="text-[#f7f8f8] text-2xl font-semibold pb-4">Subscription Licenses</h2>

                    <p class="pb-6 text-lg tracking-tighter">
                        We only use subscriptions for products that require a lot of maintenance, support and dedicated infrastructure.
                        These products are often hosted by us and require a lot of resources to run, so we can't offer them as perpetual
                        or lifetime licenses.
                    </p>

                    <p class="pb-6 text-lg tracking-tighter">
                        Subscriptions are usually billed monthly or yearly and can be cancelled at any time. If you cancel them, you lose 
                        access to these tools after your current billing cycle.
                    </p>

                    <p class="pb-6 text-lg tracking-tighter">
                        As a business with subscription fatige, we try to avoid them where we can.
                    </p>

                    <p class="pb-6 text-lg tracking-tighter">
                        If you have any question about our licenses, please <a href="mailto:hello@protoku.be" class="text-[#f7f8f8] hover:underline">contact us</a>  
                         &nbsp;or request <a href="mailto:hello@protoku.be" class="text-[#f7f8f8] hover:underline">an individual quote</a> for your team.
                    </p>
                </div>
            </div>

            
        </div>
        <Footer />
        </>
    );
}

export default License;
