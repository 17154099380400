import Navigation from "./components/navigation";
import Footer from "./components/footer";

function About() {
    return (
        <>
        <div className="mx-auto max-w-7xl px-4 ">
            <Navigation />

            <div class="text-[#8a8f98] min-h-screen">
                <div class="max-w-4xl mx-auto px-6 py-16">
                    <h1 class="text-[#f7f8f8] text-4xl font-semibold pb-8">Protoku BV</h1>

                    <h2 class="text-[#f7f8f8] text-2xl font-semibold pb-4">Address</h2>

                    <p class="pb-6 text-lg tracking-tighter">
                    Protoku BV<br />
                    Walter Thijsstraat 7<br />
                    3500 Hasselt<br />
                    Belgium
                    </p>

                    <h2 class="text-[#f7f8f8] text-2xl font-semibold pb-4">Legal Information</h2>


                    <p class="pb-6 text-lg tracking-tighter">
                    Company number: 686.633.603<br />
                    VAT number: BE0686633603<br />
                    IBAN: BE66 1030 5301 7743<br />
                    BIC: NICA BE BB
                    </p>

                    <h2 class="text-[#f7f8f8] text-2xl font-semibold pb-4">Email</h2>

                    <p class="pb-6 text-lg tracking-tighter">
                        <a href="mailto:hello@protoku.be" class="text-[#f7f8f8] hover:underline">hello@protoku.be</a>
                    </p>
                </div>
            </div>

            
        </div>
        <Footer />
        </>
    );
}

export default About;
