import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Privacy from './Privacy';
import Home from './Home';
import Terms from './Terms';
import License from './License';
import About from './About';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/license" element={<License />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </Router>
  );
}

export default App;
